<template lang="pug">
    ul#fundadores
        li(v-for="item in items")
            .img(v-if="item.image")
                img(:src="item.image")
            .letter(v-else)
                p {{item.name | firstLetter}}
            .info
                h4.name {{item.name}}
                p(v-if="item.description").description {{item.description}}
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "component-fundadores",
    props,
    filters: {
        firstLetter(value) {
            return value.charAt()
        }
    },
    data() {
        return {
            items: [],
        }
    },
    mounted() {
		this.items = this.contents.content.map(item => ({
			...item,
			image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`
		}))
	}
}
</script>

<style lang="stylus" scoped src="./Fundadores.styl"></style>
